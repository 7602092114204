import { FC, useEffect, useState } from 'react';

import { TemplatePage } from '@genially/public-web-components';
import WithLayout from '../WithLayout';
import { translate } from 'core/infrastructure/translator';

interface DataProps {
  content: any;
}

const Template: FC<DataProps> = ({ content }) => {
  const translateFromContent = (key: string) => translate(content.langcode.value, key);
  const [fullBreadcrumb, setBreadcrumb] = useState([] as any[]);

  const ctaLang = content.langcode.value === 'en' ? '/' : '/' + content.langcode.value + '/';

  const customBanner = {
    entity: {
      animation: 'none',
      behaviorSettings: {
        rowStyle: {
          background: {
            backgroundType: 'solid',
            baseColor: {
              color: 'rAkihabara',
            },
          },
        },
      },
      buttonStyle: {
        size: 'big',
        variant: 'tertiary',
      },
      button: {
        title: translateFromContent('templates.templatebanner3'),
        url: {
          path: 'https://auth.genially.com' + ctaLang + 'signup',
        },
      },
      langcode: {
        value: content.langcode.value,
      },
      subtitle: translateFromContent('templates.templatebanner2'),
      subtitleType: 'paragraphL',
      subtitleColor: 'rWhite',
      title: translateFromContent('templates.templatebanner1'),
      titleColor: 'rWhite',
      titleType: 'title2XXL',
      type: 'ParagraphBanner',
    },
  };

  const info = [
    { text: translateFromContent('templates.detailinfo1') },
    { text: translateFromContent('templates.detailinfo2') },
    { text: translateFromContent('templates.detailinfo3') },
    { text: translateFromContent('templates.detailinfo4') },
    { text: translateFromContent('templates.detailinfo5') },
  ];

  useEffect(() => {
    function getCDPurl(lang: string) {
      let url = '/templates/';
      switch (lang) {
        case 'pt-br': {
          url = '/' + lang + '/modelos/';
          break;
        }
        case 'es': {
          url = '/' + lang + '/plantillas/';
          break;
        }
        case 'de': {
          url = '/' + lang + '/vorlagen/';
          break;
        }
        case 'fr': {
          url = '/' + lang + '/modeles/';
          break;
        }
        case 'it': {
          url = '/' + lang + '/templates/';
          break;
        }
        default: {
          url = '/templates/';
          break;
        }
      }
      return url;
    }
    const fullBreadcrumb = [
      {
        url: getCDPurl(content.langcode.value),
        title: translateFromContent('templates.breadcrumbback'),
      },
    ].concat(content.breadcrumb);
    setBreadcrumb(fullBreadcrumb);
  }, []);
  return (
    <TemplatePage
      title={content.displayTitle}
      banner={customBanner}
      description={content.description ? content.description.value : undefined}
      breadcrumbItems={fullBreadcrumb}
      idView={content.idView}
      categories={content.categories ? content.categories : []}
      categoriesTitle={translateFromContent('templates.categories')}
      useTemplate={translateFromContent('templates.usetemplate')}
      relatedTemplates={content.relatedTemplates ? content.relatedTemplates : []}
      relatedTemplatesTitle={translateFromContent('templates.related')}
      relatedTemplatesCTA={translateFromContent('templates.relatedcta')}
      idGenially={content.IdGenially}
      lang={ctaLang}
      info={info}
      changed={content.changed}
      price={content.price}
      liveSession={content.liveSession}
      liveSessionText={translateFromContent('templates.livesession')}
      aspectRatio={content.aspectRatio}
    />
  );
};

export default WithLayout(Template);
